import { React } from 'react';
import { Dialog } from '../../../dialog/Dialog';
import logo from './logo-white.svg';
import styles from './AboutDialog.module.css';

export function AboutDialog(props) {
    return <Dialog modal={true} show={props.show} label='About Star PM'>
        <div className={styles.aboutDialog}>
            <div>
                <h1>
                    <img src={logo} alt="IRIS | Star Practice Management" width="350" />
                </h1>
                <h2>Version 2021.2.0.1</h2>
                <p>Innovative, feature-rich, technically advanced practice management software</p>
                <p><a href='/releasenotes.html'>View release notes</a></p>
                <p>
                    <small>Copyright © Star Professional Software Solutions Limited, Star Americas Software Solutions LLC 2021</small>
                </p>
                <h2>Licence</h2>
                <p>Licensed to <strong>ABC &amp; Demo Partners LLC</strong></p>
                <h2>SQL Server Edition</h2>
                <p>
                    <small>Microsoft SQL Server 2019 (RTM) - 15.0.2000.5
Developer Edition (64-bit)
                    </small>
                </p>
            </div>
        </div>
    </Dialog>
}