import { React, useState } from 'react';
import styles from './ResultItem.module.css';

export function ResultItem(props) {
    return <li className={styles.resultItem}>
        <a href='#' className={styles.resultLink}>
            <i className='fad fa-user-tie'></i>
            <span className={styles.resultText}>
                {props.title}
            </span>
        </a>
    </li>
}