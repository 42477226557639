import { React, useEffect, useState, useRef, createRef } from 'react';
import { ContextMenu, ContextMenuItem } from '../../context-menu/ContextMenu';
import styles from './UserMenu.module.css';

export function UserMenu() {
    const ref = createRef();

    const [username, setUsername] = useState('');
    const [initials, setInitials] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [status, setStatus] = useState('loading');

    const [showContext, setShowContext] = useState(false);
    const contextOffset = useRef({
        left: 0,
        top: 0,
    });

    const handleClick = () => {
        const rect = ref.current.getBoundingClientRect();
        contextOffset.current = {
            right: 0,
            top: Math.ceil(rect.bottom)
        };
        setShowContext(!showContext);
    }

    useEffect(() => {
        fetch('/data/me.json')
            .then(r => r.json())
            .then(me => {
                setUsername(me.name);
                setInitials(me.initials);
                setEmailAddress(me.emailAddress);
                setStatus('loaded');
            });
    }, [setUsername]);

    useEffect(() => {
        const closeIfOpen = () => {
            if (showContext) {
                setShowContext(false);
            }
        }

        document.addEventListener('click', closeIfOpen);
        document.addEventListener('contextmenu', closeIfOpen);
        return () => {
            document.removeEventListener('click', closeIfOpen);
            document.removeEventListener('contextmenu', closeIfOpen);
        }
    }, [showContext]);

    useEffect(() => {
        const closeContextOnEscape = e => {
            if (e.key === "Escape" && showContext) {
                setShowContext(false);
            }
        }
        window.addEventListener('keydown', closeContextOnEscape);
        return () => {
            window.removeEventListener('keydown', closeContextOnEscape);
        }
    }, [showContext]);

    const getClassName = () => {
        return showContext ? styles.user + ' ' + styles.active : styles.user;
    }

    if (status === 'loading') {
        return <div className={styles.user}>
            <i className='fas fa-spinner fa-spin'></i>
        </div>
    } else {
        return <div ref={ref} className={getClassName()}>
            <button onClick={handleClick}>
                <i className='fal fa-user-circle'></i>
            </button>
            <ContextMenu title={username} subtitle={emailAddress} show={showContext} offset={contextOffset}>
                <ContextMenuItem key='settings' icon='fa-cog' label='Settings' />
                <ContextMenuItem key='mobile' icon='fa-mobile-alt' label='Get the mobile app' />
                <ContextMenuItem key='sign-out' icon='fa-power-off' label='Sign out' />
            </ContextMenu>
        </div>
    }
}