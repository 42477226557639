import { React, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    openApp,
    openAppInNewWindow,
    unpinApp,
    isWorkspaceOpen,
    closeApp
} from '../../../features/launcher/launcherSlice';

import { NavbarItem } from '../navbar-item/NavbarItem';
import { ContextMenu, ContextMenuItem } from '../../context-menu/ContextMenu';
import styles from './PinnedItem.module.css';

export function PinnedItem(props) {
    // this should be the full app object
    const app = props.app;
    const iconClass = `fad ${app.icon}`;
    
    const dispatch = useDispatch();

    const isOpen = useSelector(state => isWorkspaceOpen(state, app.key));
    const isActive = useSelector(state => state.launcher.currentApp === app.key);
    const [showContext, setShowContext] = useState(false);
    const contextOffset = useRef({
        left: 0,
        top: 0,
    });

    const handleClick = (e) => {
        e.preventDefault();
        navActions.open();
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        contextOffset.current = {
          left: e.clientX,
          top: e.clientY,
        };
        setShowContext(!showContext);
    }

    const getClassName = () => {
        let className = styles.pinnedLink;
        if (isOpen) {
            className += ` ${styles.open}`;
        }

        if (isActive) {
            className += ` ${styles.active}`;
        }

        return className;
    }
    
    const navActions = {
        open: () => {
            dispatch(openApp(app.key));
            setShowContext(false);
        },
        openInNewWindow: () => {
            dispatch(openAppInNewWindow(app));
            setShowContext(false);
        },
        close: () => {
            dispatch(closeApp(app.key));
            setShowContext(false);
        },
        unPin: () => {
            dispatch(unpinApp(app.key));
            setShowContext(false);
        }
    }

    useEffect(() => {
        const closeIfOpen = () => {
            if (showContext) {
                setShowContext(false);
            }
        }

        document.addEventListener('click', closeIfOpen);
        document.addEventListener('contextmenu', closeIfOpen);
        return () => {
            document.removeEventListener('click', closeIfOpen);
            document.removeEventListener('contextmenu', closeIfOpen);
        }
    }, [showContext]);

    useEffect(() => {
        const closeContextOnEscape = e => {
            if (e.key === "Escape" && showContext) {
                setShowContext(false);
            }
        }
        window.addEventListener('keydown', closeContextOnEscape);
        return () => {
            window.removeEventListener('keydown', closeContextOnEscape);
        }
    }, [showContext]);

    const contextItems = app.actions ? app.actions.map(action => {
        return {
            icon: action.icon,
            label: action.label,
            href: action.href,
            onClick: () => {
                window.open(action.href, action.label, action.options);
            }
        };
    }) : [];

    return <NavbarItem>
        <a href={app.href}
           className={getClassName()}
           title={app.title}
           onClick={handleClick}
           onContextMenu={handleContextMenu}>
            <i className={iconClass}></i>
            <span>{app.title}</span>
        </a>
        <ContextMenu title={app.title} show={showContext} offset={contextOffset}>
            {contextItems.map(item => <ContextMenuItem key={item.href} icon={item.icon} label={item.label} onClick={item.onClick} />)}
            <ContextMenuItem key='open' icon='fa-arrow-up-right' label='Open' onClick={navActions.open} />
            <ContextMenuItem key='opennewwin' icon='fa-arrow-up-right-from-square' label='Open in new window' onClick={navActions.openInNewWindow} />
            <ContextMenuItem key='close' icon='fa-close' label='Close' onClick={navActions.close} />
            <ContextMenuItem key='unpin' icon='fa-bookmark-slash' label='Un-pin' onClick={navActions.unPin} />
        </ContextMenu>
    </NavbarItem>
}