import React from 'react';
import { useSelector } from 'react-redux';
import {
    getWorkspaces
} from '../../features/launcher/launcherSlice';

import { Welcome } from './welcome/Welcome';
import { Workspace } from './workspace/Workspace';
import styles from './Workspaces.module.css';

export function Workspaces() {
    const workspaces = useSelector(getWorkspaces);

    return <main className={styles.workspaces} aria-label="Workspace">
        {workspaces.length === 0 ?
            <Welcome /> :
            workspaces.map(w => <Workspace key={w} app={w} />)
        }
    </main>
}