import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAppsFromApi, getPinnedFromApi } from './launcherAPI';

// todo: pinned apps should be loaded
// from the server rather than set here
const initialState = {
    pinned: [],
    currentApp: "",
    apps: [],
    workspaces: [],
    status: 'idle'
}

export const fetchApps = createAsyncThunk(
    'launcher/fetchApps',
    async () => {
        const apps = await getAppsFromApi();
        const pinned = await getPinnedFromApi();
        return {
            apps: apps,
            pinned: pinned
        };
    }
)

export const launcherSlice = createSlice({
    name: 'launcher',
    initialState,
    reducers: {
        openApp: (state, action) => {
            console.groupCollapsed('Opening app %s', action.payload);

            if (state.workspaces.indexOf(action.payload) < 0) {
                state.workspaces.push(action.payload);
                console.log('Creating application workspace');
            } else {
                console.log('Application is already open');
            }

            console.log('Setting current workspace to %s', action.payload);
            state.currentApp = action.payload;
            console.groupEnd();
        },

        openAppInNewWindow: (state, action) => {
            console.groupCollapsed('Opening app %s in a new window', action.payload);
            const app = action.payload;
            
            const workspaceIndex = state.workspaces.indexOf(app.key);
            if (workspaceIndex >= 0) {
                console.log('App is already open in a workspace, closing');
                state.workspaces.splice(workspaceIndex, 1);
            }

            console.log('Opening new popout window');
            window.open(app.href, app.key, 'scrollbars=no, resizable=yes, location=no, status=no');

            console.groupEnd();
        },

        closeApp: (state, action) => {
            console.groupCollapsed('Closing app %s', action.payload);

            const ix = state.workspaces.indexOf(action.payload);
            if (ix >= 0) {
                state.workspaces.splice(ix, 1);
                console.log('Removing workspace');
            } else {
                console.warn('The app is not currently open in any workspaces');
            }

            if (state.currentApp === action.payload) {
                if (state.workspaces.length > 0) {
                    state.currentApp = state.workspaces[state.workspaces.length - 1];
                    console.log('Current workspace is now %s', state.currentApp);
                } else {
                    state.currentApp = '';
                    console.log('No workspaces left, reverting to welcome screen');
                }
            }

            console.groupEnd();
        },

        pinApp: (state, action) => {
            console.groupCollapsed('Pinning app %s', action.payload);

            const ix = state.pinned.indexOf(action.payload);
            if (ix < 0) {
                state.pinned.push(action.payload);
                console.log('Pinning application');
            } else {
                console.warn('App is already pinned');
            }

            console.groupEnd();
        },

        unpinApp: (state, action) => {
            console.groupCollapsed('Un-pinning app %s', action.payload);

            const ix = state.pinned.indexOf(action.payload);
            if (ix >= 0) {
                state.pinned.splice(ix, 1);
                console.log('Un-pinning application');
            } else {
                console.warn('App was not pinned');
            }

            console.groupEnd();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchApps.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchApps.fulfilled, (state, action) => {
                state.status = '';
                state.apps = action.payload.apps;
                state.pinned = action.payload.pinned;
            });
    }
})

export const { openApp, openAppInNewWindow, closeApp, pinApp, unpinApp } = launcherSlice.actions;

export const getWorkspaces = (state) => state.launcher.workspaces;

export const isWorkspaceOpen = (state, key) => state.launcher.workspaces.indexOf(key) >= 0;

export const isPinned = (state, key) => state.launcher.pinned.indexOf(key) >= 0;

export const selectStatus = (state) => state.launcher.status;

export const selectCurrentApp = (state) => state.launcher.currentApp;

export const selectApps = (state) => state.launcher.apps;

export const selectApp = (state, key) => {
    for (const app of state.launcher.apps) {
        if (app.key === key) {
            return app;
        }
    }

    return null;
}

export const selectPinnedApps = (state) => {
    // we haven't loaded the app list yet, so we can't
    // load the pinned app list; quit whilst we're ahead
    if (!state.launcher.apps || state.launcher.apps.length === 0) {
        return [];
    }

    // we're going to use the pinned app list as keys,
    // then load all apps that match
    const apps = state.launcher.apps;
    const mapper = key => apps.find(app => app.key === key);
    return state.launcher.pinned.map(mapper);
}

export default launcherSlice.reducer;