import { React, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectStatus,
    selectPinnedApps,
    fetchApps
} from '../../features/launcher/launcherSlice';

import styles from './Navbar.module.css';

import { PinnedItem } from './pinned-item/PinnedItem';
import { AppsMenu } from './apps-menu/AppsMenu';

export function Navbar() {
    const dispatch = useDispatch();

    // get some state properties from the global launcher
    const status = useSelector(selectStatus);
    const pinned = useSelector(selectPinnedApps);

    const navbarContextMenu = (e) => {
        // we want to prevent the default right-click
        // action on the context menu, as all child 
        // elements have their own
        e.preventDefault();
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchApps());
        }
    }, [status, dispatch]);

    return <nav className={styles.launcher} aria-label="Launcher menu" onContextMenu={navbarContextMenu}>
        <ol className={styles.pinnedItems} aria-label="Pinned applications">
            {pinned.map(pin =>
                <PinnedItem key={pin.key} app={pin} />
            )}
        </ol>
        <ul className={styles.fixedItems} aria-label="Controls">
            <AppsMenu />
        </ul>
    </nav>
}