import { React, useState, useEffect, createRef } from 'react';
import styles from './Dialog.module.css';

export function Dialog(props) {
    const label = props.label || 'Untitled dialog';
    const modal = props.modal ? 'true' : 'false';
    const ref = createRef();

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        setShowDialog(props.show);
    }, [props, showDialog]);

    // useEffect(() => {
    //     const closeDialogOnClickOutside = e => {
    //         // we're not interested if the dialog is already closed
    //         console.log('Click - show dialog = %s', showDialog);
    
    //         if (!showDialog) return;
    
    //         // was the click inside the dialog?
    //         const parent = e.target.closest('dialog')
    //         if (parent === null) {
    //             // no - so we need to close the dialog
    //             console.log('Closing dialog');
    //             setShowDialog(false);
    //         } else {
    //             console.log('No need to close dialog');
    //         }
    //     }

    //     window.addEventListener('click', closeDialogOnClickOutside);
    //     return () => {
    //         window.removeEventListener('click', closeDialogOnClickOutside);
    //     }
    // }, [showDialog]);

    if (showDialog) {
        return <dialog ref={ref} modal='true' open className={styles.dialog} aria-label={label} aria-modal={modal}>
            {props.children}
        </dialog>
    } else {
        return <></>
    }
}