import { React, useState, useEffect, createRef } from 'react';
import { NavbarItem } from '../navbar-item/NavbarItem';
import { MenuGrid } from './menu-grid/MenuGrid';

export function AppsMenu() {
    const [showMenu, setShowMenu] = useState(false);
    const [offsetY, setOffsetY] = useState(0);

    const ref = createRef();

    const toggleMenu = (e) => {
        e.preventDefault();
        
        const node = ref.current;
        const rect = node.getBoundingClientRect();
        setOffsetY((window.innerHeight - rect.bottom) - 1);
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        const closeMenuOnEscape = e => {
            if (e.key === "Escape" && showMenu) {
                setShowMenu(false);
            }
        }
        window.addEventListener('keydown', closeMenuOnEscape);
        return () => {
            window.removeEventListener('keydown', closeMenuOnEscape);
        }
    });

    useEffect(() => {
        const closeMenuOnClickOutside = e => {
            // we're not interested if the menu is already closed
            if (!showMenu) return;
    
            // was the click inside the menu?
            const menuParent = e.target.closest('#appsMenuGrid')
            if (menuParent === null) {
                // no - so we need to close the menu
                setShowMenu(false);
            }
        }
        window.addEventListener('click', closeMenuOnClickOutside);
        window.addEventListener('contextmenu', closeMenuOnClickOutside);
        return () => {
            window.removeEventListener('click', closeMenuOnClickOutside);
            window.removeEventListener('contextmenu', closeMenuOnClickOutside);
        }
    });
    
    return <NavbarItem>
        <a ref={ref} href="#apps" onClick={toggleMenu}>
            <i className='fas fa-grid'></i>
            <span>About</span>
        </a>
        {showMenu ? <MenuGrid id='appsMenuGrid' offsetY={offsetY} /> : <></>}
    </NavbarItem>
}