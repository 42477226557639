import { React, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    isPinned,
    isWorkspaceOpen,
    openApp,
    openAppInNewWindow,
    closeApp,
    pinApp,
    unpinApp
} from '../../../../features/launcher/launcherSlice';
import { ContextMenu, ContextMenuItem } from '../../../context-menu/ContextMenu';

import styles from './MenuGrid.module.css';

export function MenuItem(props) {
    const dispatch = useDispatch();

    const appIsOpen = useSelector(s => isWorkspaceOpen(s, props.app));
    const appIsPinned = useSelector(s => isPinned(s, props.app));

    const [showContext, setShowContext] = useState(false);
    const contextOffset = useRef({
        left: 0,
        top: 0,
    });

    const getClassName = () => {
        // use a different style to show that an
        // app is already pinned
        return appIsPinned ?
            `fas ${props.icon}` :   // solid fill indicates pinned
            `fal ${props.icon}`;    // outline indicates un-pinned
    }

    const handleClick = e => {
        e.preventDefault();
        navActions.open();
    }

    const handleContextMenu = e => {
        e.preventDefault();
        contextOffset.current = {
          left: e.clientX,
          top: null,
          bottom: window.innerHeight - e.clientY,
        };
        setShowContext(!showContext);
    }
    
    const navActions = {
        open: () => {
            dispatch(openApp(props.app));
            setShowContext(false);
        },
        openInNewWindow: () => {
            dispatch(openAppInNewWindow(props.app));
            setShowContext(false);
        },
        close: () => {
            dispatch(closeApp(props.app));
            setShowContext(false);
        },
        pin: () => {
            dispatch(pinApp(props.app));
            setShowContext(false);
        },
        unPin: () => {
            dispatch(unpinApp(props.app));
            setShowContext(false);
        }
    }

    useEffect(() => {
        const closeIfOpen = () => {
            if (showContext) {
                setShowContext(false);
            }
        }

        document.addEventListener('click', closeIfOpen);
        document.addEventListener('contextmenu', closeIfOpen);
        return () => {
            document.removeEventListener('click', closeIfOpen);
            document.removeEventListener('contextmenu', closeIfOpen);
        }
    }, [showContext]);

    const contextItems = [
        <ContextMenuItem key='open' icon='fa-arrow-up-right' label='Open' onClick={navActions.open} />,
        <ContextMenuItem key='opennewwin' icon='fa-arrow-up-right-from-square' label='Open in new window' />,
        appIsPinned ?
            <ContextMenuItem key='unpin' icon='fa-bookmark-slash' label='Un-pin' onClick={navActions.unPin} /> :
            <ContextMenuItem key='pin' icon='fa-bookmark' label='Pin' onClick={navActions.pin} />
    ];

    if (appIsOpen) {
        contextItems.push(
            <ContextMenuItem key='close' icon='fa-close' label='Close' onClick={navActions.close} />
        );
    }

    return <li className={styles.menuItem}>
        <a href={props.href} className={styles.menuLink} onClick={handleClick} onContextMenu={handleContextMenu}>
            <i className={getClassName()}></i>
            <span className={styles.menuText}>{props.title}</span>
        </a>
        <ContextMenu show={showContext} offset={contextOffset}>
            {contextItems}
        </ContextMenu>
    </li>
}