import { React, useState, createRef } from 'react';
import { ResultList } from './results-list/ResultsList';
import styles from './SearchBox.module.css';

export function SearchBox() {
    const [inFocus, setInFocus] = useState(false);
    const [searchTerms, setSearchTerms] = useState('');
    const [showResults, setShowResults] = useState(false);

    const inputRef = createRef();

    const handleInput = e => {
        const value = e.target.value;
        setSearchTerms(value);
        setShowResults(!!value);
    }

    const handleFocus = () => {
        setInFocus(true);
        setShowResults(!!searchTerms);
    }

    const lostFocus = () => {
        setInFocus(false);
        setShowResults(!!searchTerms);
    }

    const iconClick = () => {
        inputRef.current.focus();
    }

    const getClassName = () => {
        let className = styles.wrapper;
        if (inFocus) {
            className += ` ${styles.focus}`;
        }

        return className;
    }

    return <div className={getClassName()}>
        <i className='fas fa-search' onClick={iconClick}></i>
        <input ref={inputRef} className={styles.search} type='search' placeholder='Search for…' onFocus={handleFocus} onBlur={lostFocus} onInput={handleInput} />
        {showResults ? <ResultList terms={searchTerms} /> : <></>}
    </div>
}