import { React, useState } from 'react';
import styles from './Notifications.module.css';

export function Notifications() {
    // todo: overlay
    // todo: show a context menu to list items
    // todo: show items being acknowledged
    // todo: we need a state handler for these
    return <div>
        <button>
            <i className='fal fa-bell'></i>
        </button>
    </div>
}