import { React } from 'react';
import styles from './Header.module.css';
import { HelpMenu } from './help-menu/HelpMenu';
import { Logo } from './logo/Logo';
import { SearchBox } from './search-box/SearchBox';
import { UserMenu } from './user-menu/UserMenu';
import { Notifications } from './notifications/Notifications';

export function Header() {
    return <header className={styles.header}>
        <div className={styles.left}>
            <Logo />
        </div>
        <div className={styles.center}>
            <SearchBox />
        </div>
        <div className={styles.right}>
            <Notifications />
            <UserMenu />
            <HelpMenu />
        </div>
    </header>
}