import React from 'react';

import { Workspaces } from './components/workspaces/Workspaces';
import { Navbar } from './components/navbar/Navbar';

import './App.css';
import { Header } from './components/header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="AppContent">
        <Navbar />
        <Workspaces />
      </div>
    </div>
  );
}

export default App;
