import { React } from 'react';
import styles from './ContextMenu.module.css';

export function ContextMenuItem(props) {
    const iconClass = `fal fa-fw ${props.icon}`;
    return <li>
        <button type="button" onClick={props.onClick}>
            <i className={iconClass}></i>
            <span className={styles.text}>{props.label}</span>
        </button>
    </li>
}

export function ContextMenu(props) {
    function ContextTitle() {
        if (props.title) {
            return <li className={styles.title}>{props.title}</li>
        } else {
            return <></>
        }
    }

    function ContextSubtitle() {
        if (props.subtitle) {
            return <li className={styles.subtitle}>{props.subtitle}</li>
        } else {
            return <></>
        }
    }

    if (props.show) {
        return <menu className={styles.context} style={
            {
                left: props.offset.current.left,
                right: props.offset.current.right,
                top: props.offset.current.top,
                bottom: props.offset.current.bottom
            }
        }>
            <ContextTitle />
            <ContextSubtitle />
            {props.children}
        </menu>
    } else {
        return <></>
    }
}