import React, { createRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    closeApp,
    openAppInNewWindow,
    selectApp,
    selectCurrentApp,
} from '../../../features/launcher/launcherSlice';
import styles from './Workspace.module.css';

export function Workspace(props) {
    const [title, setTitle] = useState('…');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();
    const frameRef = createRef();

    const currentApp = useSelector(selectCurrentApp);
    const targetApp = useSelector(s => selectApp(s, props.app));
    const isOpen = props.app === currentApp;

    const workspaceStyle = {
        display: isOpen ? 'flex' : 'none'
    }

    const frameStyle = {
        display: loading ? 'none' : 'block'
    }

    const popOutWorkspace = () => {
        dispatch(openAppInNewWindow(targetApp));
    }

    const closeWorkspace = () => {
        dispatch(closeApp(props.app));
    }

    const frameLoaded = (e) => {
        const frame = frameRef.current;
        const frameWindow = frame.contentWindow;
        frameWindow.onerror = e => {
            setError(true);
            console.error(e);
        }

        setTitle(targetApp.title);
        setLoading(false);
    }

    function WorkspaceLoader(props) {
        if (props.show) {
            return <div className={styles.workspaceLoader}>
                <progress style={{display:"none"}} aria-label='Please wait, loading'></progress>
                <i className='fas fa-circle-notch fa-spin fa-2xl'></i>
            </div>
        } else {
            return <></>
        }
    }

    function WorkspaceTitle() {
        if (loading) {
            return <i className='far fa-spin fa-spinner'></i>
        } else {
            return <span>{title}</span>
        }
    }

    return <section className={styles.workspace} style={workspaceStyle}>
        <header>
            <h1>
                <WorkspaceTitle />
                {
                    error ? <span>&nbsp;<i className='fas fa-bomb'></i></span> : <></>
                }
            </h1>
            <button value="popOut" title="Open in new window" onClick={popOutWorkspace}>
                <i className="fas fa-fw fa-arrow-up-right-from-square"></i>
                <span className="sr-only">Open in new window</span>
            </button>
            <button value="close" title="Close app" onClick={closeWorkspace}>
                <i className="fas fa-fw fa-close"></i>
                <span className="sr-only">Close app</span>
            </button>
        </header>
        <main>
            <WorkspaceLoader show={loading} />
            <iframe ref={frameRef} className={styles.workspaceFrame}
                style={frameStyle}
                title={targetApp.title}
                src={targetApp.href}
                onLoad={frameLoaded}></iframe>
        </main>
    </section>
}