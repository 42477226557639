export async function getAppsFromApi() {
    try {
        const r = await fetch('/data/apps.json')
        if (r.status === 200) {
            return await r.json();
        } else {
            throw new Error('Error ' + r.status + ' whilst fetching apps');
        }
    } catch (e) {
        console.error('Error loading apps: ' + e);
        throw e;
    }
}

export async function getPinnedFromApi() {
    try {
        const r = await fetch('/data/pinned.json')
        if (r.status === 200) {
            return await r.json();
        } else {
            throw new Error('Error ' + r.status + ' whilst fetching pinned');
        }
    } catch (e) {
        console.error('Error loading pinned: ' + e);
        throw e;
    }
}