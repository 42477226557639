import { React, useState } from 'react';
import { AboutDialog } from './about-dialog/AboutDialog';

export function HelpMenu() {
    const [showDialog, setShowDialog] = useState(false);

    const toggleDialog = e => {
        e.preventDefault();
        setShowDialog(!showDialog);
    }

    return <div>
        <button onClick={toggleDialog}>
            <i className='fal fa-question-circle'></i>
        </button>
        <AboutDialog show={showDialog}></AboutDialog>
    </div>
}