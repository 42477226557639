import React from 'react';
import styles from './Welcome.module.css';

export function Welcome() {
    return <div className={styles.welcomePanel}>
        <div className={styles.inner}>
            <article className={styles.content}>
                <p>Welcome to STAR</p>
            </article>
        </div>
    </div>
}