import { React } from 'react';
import { useSelector } from 'react-redux';
import {
    selectApps
} from '../../../../features/launcher/launcherSlice';

import styles from './MenuGrid.module.css';
import { MenuItem } from './MenuItem';

export function MenuGrid(props) {
    const allApps = useSelector(selectApps);

    const inlineStyle = {
        bottom: 0
    };
    
    return <menu id={props.id} className={styles.grid} style={inlineStyle}>
        {allApps.map(app =>
            <MenuItem key={app.key} app={app.key} icon={app.icon} title={app.title} href={app.href} />
        )}
    </menu>
}