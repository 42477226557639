import { React, useState } from 'react';
import { ResultItem } from '../result-item/ResultItem';
import styles from './ResultsList.module.css';

export function ResultList(props) {
    const fakeResults = [
        "Akron LLC",
        "Andrews Restaurant Supply",
        "Archway Motors",
        "Bailey",
        "Barbeque Supplies",
        "Barcelona AFC",
        "Barr Trust",
    ]

    return <div className={styles.results}>
        <ol className={styles.resultList}>
            {fakeResults.map(r =>
                <ResultItem key={r} title={r} />
            )}
        </ol>
    </div>
}